@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

::selection {
  background: var(--scroll);
  color: var(--background);
}

:root, :root.light {
  --background: #fff;
  --backgroundDark: #838383;
  --text: #8898aa;
  --placeholder: #cacaca;
  --primary: #afafaf; 
  --secondary: #dadada;
  --scroll: #b09dff;

}

.dark {
  --background: #1a1a1a;
  --backgroundDark: #a8a8a8;
  color: #fff;
  --text: rgb(190, 190, 190);
  --placeholder: #cacaca;
  --primary: #757575; 
  --secondary: #666666;
  --scroll: #b09dff;
}

.cafe {
  --background: #ceb18d;
  --backgroundDark: #aa9172;
  --text: #14120f;
  --placeholder: #a8a8a8;
  --primary: #14120f; 
  --secondary: #ce9a5b;
  --scroll: #14120f;
}

.terminal {
  --background: #000000;
  --backgroundDark: #a8a8a8;
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 400;
  --text: #39ff14;
  --placeholder: #cacaca;
  --primary: #757575; 
  --secondary: #666666;
  --scroll: #39ff14;
}

.blueberry {
  --background: #212b42;
  --backgroundDark: #329cff;
  color: #fff;
  --text: #add7ff;
  --placeholder: #cacaca;
  --primary: #0c639c; 
  --secondary: #0082be;
  --scroll: #0082be;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll);
  border-radius: 10px;
  background-clip: padding-box;
}