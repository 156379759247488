.ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }
  
    ul,
    ol {
      padding: 0 1rem;
    }
  }
  
  .bubble-menu {
    display: flex;
    background-color: var(--backgroundDark);
    padding: 0.2rem;
    width: fit-content;
    border-radius: 0.5rem;
  
    button {
      border: none;
      background: none;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0 0.2rem;
      opacity: 0.6;
  
      &:hover,
      &.is-active {
        opacity: 1;
        color: var(--text);
      }

      .cafe &, .light & {
        color: white;
      }
    }
  }
  
  .floating-menu {
    display: flex;
    background-color: var(--backgroundDark);
    padding: 0.2rem;
    border-radius: 0.5rem;
    width: fit-content;
  
    button {
      border: none;
      background: none;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0 0.2rem;
      opacity: 0.6;
  
      &:hover,
      &.is-active {
        opacity: 1;
        color: var(--text);
      }
    
      .cafe &, .light & {
        color: white;
      }
    }
  }