html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  display: block;
  font-family: Inter, sans-serif;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  overflow: hidden
}

#root {
  background-color: var(--background);
}

* {
  box-sizing: border-box;
}

.ProseMirror {
  background: var(--background);
  resize: none;
  font-size: 1.1em;
  padding: 25px 25%;
  padding-bottom: 150px;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: var(--text);
  overflow: auto;
  height: 100vh;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  caret-color: var(--text);
  z-index: 2;
}

.terminal .ProseMirror {
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  font-size: 1.1rem;
}

.ProseMirror:focus {
  outline: none;
}

.count {
  color: var(--count);
  font-size: 0.9rem;
  padding: 0 0.3rem;
}

.tips {
  position: fixed;
  transform: translate(0%, -50%);
  top: 50%;
  right: 0;
  width: 30%;
  margin-right: 2rem;
  padding: 1rem;
  background-color: var(--background);
  border: 3px solid var(--primary);
  border-radius: 4px;
  animation: tips-pop-out 0.3s ease-out;
  -webkit-animation: tips-pop-out 0.3s ease-out;
  opacity: 0;
  z-index: 1;

  .tips-content-title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--text);
  }

  .tips-content-text {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: var(--text);
  }
}

.tips.show {
  animation: tips-pop-in 0.3s ease-in;
  -webkit-animation: tips-pop-in 0.3s ease-in;
  opacity: 1;
  z-index: 3;
  box-shadow: -4px -2px 35px -6px rgba(0,0,0,0.3);
}

br {
  display: block;
  margin: 10px 0;
}

hr {
  color: var(--primary);
  margin: 1.4rem 0;
}

kbd {
  display: inline-block;
  padding: 0.2rem 0.4rem;
  margin: 0 0.2rem;
  font-size: 1rem;
  border-radius: 5px;
  background-color: var(--secondary);
}

.shortcut {
  font-size: 1rem;
  margin: 0.6rem 0;
}

.actions {
  position: fixed;
  transform: translate(0%, -50%);
  bottom: 0rem;
  right: 0;
  margin-right: 2rem;
  animation: actions-pop-in 0.5s ease-in;
  -webkit-animation: actions-pop-in 0.5s ease-in;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: flex-end;

  .actionsChild {
    width: fit-content;
    align-items: center;
    display: flex;
    backdrop-filter: blur(1px);
    border-radius: 20px;
    flex-direction: row;
    height: 30px;

    .count {
      color: var(--text);
      font-size: 0.9rem;
      padding: 0 0;
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-weight: bold;
      transition: ease-in-out 0.4s;
      margin: 0 0.5rem;

      .discord {
        width: 30px;
        height: 30px;
        margin: 0 0.3rem;
      }

      .import {
        width: 30px;
        height: 30px;
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
        color: #606e7d;
        margin: 0 0.3rem;
        position: relative;
      }

      .import:hover {
        cursor: pointer;
        transform: translateY(-2px);
      }

      .discord:hover {
        opacity: 1;
        .discordColor {
          fill: #5865f2;
        }
      }

      .discordColor {
        transition: ease-in-out 0.2s;
      }
      .tooltip {
        color: var(--primary) !important;
        font-weight: 600 !important;
        font-family: Inter !important;
        outline-width: 4px !important;
        border-color: var(--primary) !important;
        border-radius: 10px !important;
      }
    }
  }

  .save {
    padding: 0.5rem;
    border-radius: 4px;
    border: none;
    outline: none;
    font-size: 0.9rem;
    background-color: var(--scroll);
    color: var(--background);
    margin-left: 1rem;
    transition: ease-in-out 0.2s;
    width: 5rem;
    cursor: pointer;
  }
}

.theme-select {
  color: var(--text) !important;
  border: 3px solid var(--primary) !important;
  border-radius: 4px !important;
  background-color: var(--background);
  height: 100%;
  font-size: 0.9rem;
  padding: 0 0.5rem;
  cursor: pointer;
}
.theme-select:focus,
.theme-select:active {
  outline: none;
  border: 3px solid var(--backgroundDark) !important;
}
@media screen and (max-width: 768px) {
  .shortcut {
    font-size: 0.8rem;
  }
  .ProseMirror {
    padding-bottom: 70px;
    width: 100%;
  }

  .tips {
    animation: none;
  }
  .actions {
    width: 100%;
    background-color: var(--background);
    bottom: 0;
    padding: 0.4rem;
    .actionsChild {
      padding-right: 0;
      padding-left: 0;
      margin: auto;

      .icons .discord {
        width: 40px;
        height: 40px;
      }

      .save {
        padding: 0.7rem 0.4rem;
        font-size: 0.9rem;
        margin-left: 0.4rem;
      }
    }
  }
  .tips.show {
    display: none;
  }
  .tips-content-text {
    font-size: 1rem;
  }
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}

label input {
  visibility: hidden;
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
}
label span {
  height: 12px;
  width: 12px;
  border: 1px solid grey;
  display: inline-block;
}
[type="checkbox"]:checked + span {
  background-color: var(--primary);
}
[type="checkbox"] + span {
  transition: ease-in-out 0.4s;
}
li[data-checked="true"] div {
  text-decoration: line-through;
  opacity: 0.5;
  transition: ease-in-out 0.2s;
}
[type="checkbox"] + span:hover {
  background-color: var(--text);
  opacity: 0.7;
  cursor: pointer;
}
[type="checkbox"]:checked + span:hover {
  background-color: var(--primary);
}

@-webkit-keyframes tips-pop-in {
  from {
    opacity: 0;
    right: -200px;
  }
  to {
    opacity: 1;
    right: 0px;
  }
}

@keyframes tips-pop-in {
  from {
    opacity: 0;
    right: -200px;
    z-index: 0;
  }
  to {
    opacity: 1;
    right: 0px;
    z-index: 3;
  }
}

@keyframes tips-pop-out {
  from {
    opacity: 1;
    right: 0px;
    z-index: 3;
  }
  to {
    opacity: 0;
    right: -200px;
    z-index: 0;
  }
}

@-webkit-keyframes tips-pop-out {
  from {
    opacity: 1;
    right: 0px;
  }
  to {
    opacity: 0;
    right: -200px;
  }
}

@keyframes actions-pop-in {
  from {
    opacity: 0;
    bottom: -200px;
  }
  to {
    opacity: 1;
    bottom: 0px;
  }
}
@-webkit-keyframes actions-pop-in {
  from {
    opacity: 0;
    bottom: -200px;
  }
  to {
    opacity: 1;
    bottom: 0px;
  }
}

.tab-bar {
  position: absolute;
  left: 1rem;
  top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20%;
  height: fit-content;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 20;

  .tab-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.03);

    .cafe, .light {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .terminal {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  
  .tabs {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--backgroundDark);
    gap: 0.3rem;
    padding: 0.5rem;
  }
  .tab {
    width: 100%;
    color: var(--text);
    font-size: 1rem;
    font-family: "Source Sans Pro", sans-serif;
    display: flex;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    max-width: 100%;
    animation: new-tab 0.1s ease-in-out;

    .tab-name {
      transition: ease-in-out 0.1s;
      padding: 0.3rem 0.6rem;
      padding-left: 0.5rem;
      border-radius: 4px;
      width: 80%;
      cursor: pointer;

      &[data-isonly="true"] {
        width: 100%;
      }

      div {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }

      .cafe &:hover, .light &:hover {
        color: var(--text);
        background-color: rgba(0, 0, 0, 0.1);
      }

      .terminal & {
        color: var(--backgroundDark);
      }

      .terminal &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .tab-close {
      cursor: pointer;
      transition: ease-in-out 0.1s;
      padding: 0.2rem;
      border-radius: 4px;
      font-size: 1.2rem;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }

      .cafe &:hover, .light &:hover {
        color: var(--text);
        background-color: rgba(0, 0, 0, 0.1);
      }

      .terminal &, .dark &, .blueberry & {
        color: white;
      }
    }

    &.active .tab-name {
      color: var(--backgroundDark);
      background-color: rgba(255, 255, 255, 0.03);
    }

    .cafe &.active .tab-name, .light &.active .tab-name {
      color: var(--text);
      background-color: rgba(0, 0, 0, 0.1);
    }

    .terminal &.active .tab-name {
      color: var(--text);
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .new-tab {
    margin-top: 0.5rem;
    transition: ease-in-out 0.1s;
    padding: 0.2rem 0.6rem;
    padding-left: 0.8rem;
    border-radius: 4px;
    font-size: 1.6rem;
  
    div {
      width: 100%;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }

    .cafe &:hover, .light &:hover {
      color: var(--text);
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .tab-rename {
    transition: ease-in-out 0.1s;
    border-radius: 4px;
    font-size: 1rem;
    max-width: 100%;
    border: none;
    color: var(--text);
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }
}

@keyframes new-tab {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes close-tab {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.actionsChild.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  height: 60px;
}